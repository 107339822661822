import React, { useEffect } from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Link, useStaticQuery, graphql } from "gatsby"
import ConnectSidebar from "../../components/layout/connect-sidebar"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import SEO from "../../components/seo"
import NEWOFFICEIMAGE from "../../images/newoffice.jpg"

const abbreviations = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona ",
  AR: "Arkansas",
  CA: "California ",
  CO: "Colorado ",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia ",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

const OurLocationsPage = ({ location }) => {
  useEffect(() => {
    console.log("Location key changed")
  })

  const { state = {} } = location

  const data = useStaticQuery(graphql`
    {
      allLocation {
        nodes {
          city
          state
          imagePaths
          street
          title
          zip
          hours
          googleMapsLink
          googleMapsEmbedLink
          phone
          manager
          slug
          seoURL
          type
        }
      }
    }
  `)

  const statesAbbr = [
    ...new Set(data.allLocation.nodes.map((location) => location.state)),
  ].sort()

  const headquarters = data.allLocation.nodes.find(
    (location) => location.type === "HQ"
  )
  console.log(headquarters)

  const abbr = (state && state.abbr) || statesAbbr[0]
  console.log(abbr)
  return (
    <Layout>
      <SEO
        title={`Store Locator | MA, CT, RI, NY, NJ, NH, PA, MD, MI, FL, IL, OK`}
        description={`Find an authorized Walpole Outdoors location near you. We feature showrooms in Massachusetts, Connecticut, New York, New Jersey, Florida and more!`}
      />
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about">About</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/about/our-locations">Our Locations</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar></ConnectSidebar>
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Our Locations</h1>

            <div className="location_top">
              <div className="accorditem" id="wrapper">
                <div className="locationleft">
                  <img src={headquarters.imagePaths[0]} />
                </div>
                <div className="textright">
                  <Link to={headquarters.seoURL}>
                    <h2 className="locationmheader">{headquarters.title}</h2>
                  </Link>
                  <p>
                    Serving states beyond our stores
                    <br />
                    {headquarters.street}
                    <br />
                    {headquarters.city}, {headquarters.state} {headquarters.zip}
                    <br />
                    {headquarters.hours}
                    <br />
                    <a href={`tel:${headquarters.phone}`}>
                      {headquarters.phone}{" "}
                    </a>
                  </p>

                  <p>
                    <strong>
                      <a
                        href={headquarters.googleMapsLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Get Directions
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <hr />

            <Accordion defaultActiveKey={abbr}>
              {statesAbbr.map((abbr) => {
                return (
                  <Card key={abbr}>
                    <Accordion.Toggle as={Card.Header} eventKey={abbr}>
                      {abbreviations[abbr]}{" "}
                      <FontAwesomeIcon icon={faCaretRight} />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={abbr}>
                      <div className="accord_cont">
                        {data.allLocation.nodes
                          .filter((node) => node.state === abbr)
                          .map((locationData) => {
                            return (
                              <div
                                className="accorditem"
                                id="wrapper"
                                key={locationData.title}
                              >
                                <div className="locationleft">
                                  <a href="#" rel="noreferrer">
                                    <img src={locationData.imagePaths[0]} />
                                  </a>
                                </div>
                                <div className="textright">
                                  <Link to={locationData.seoURL}>
                                    <h2 className="accordheader">
                                      {locationData.title}
                                    </h2>{" "}
                                  </Link>
                                  <p>
                                    {locationData.street}
                                    <br />
                                    {locationData.city}, {locationData.state}{" "}
                                    {locationData.zip}
                                    <br />
                                    {locationData.hours}
                                    <br />
                                    <a href={`tel:${locationData.phone}`}>
                                      {locationData.phone}
                                    </a>
                                    <br />
                                    {locationData.manager}
                                  </p>

                                  <p>
                                    <strong>
                                      <a
                                        href={locationData.googleMapsLink}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Get Directions
                                      </a>
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default OurLocationsPage
